import axios, { AxiosError, AxiosRequestConfig, AxiosRequestTransformer } from 'axios';
import { setAppError, setIsActive, setLoading } from 'redux/actions';
import { store } from 'redux/store';
import config from '../../config';
import LocalStorage from './LocalStorage';
import { CustomEventSource } from 'extended-eventsource';
import { useAuth0 } from '@auth0/auth0-react';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

export interface RequestConfig extends AxiosRequestConfig {
    showLoading?: boolean;
    hide404?: boolean;
    Authorization?: string;
    hideError?: boolean;
    persistError?: boolean;
}

/**** using Axios Request Transformer to handle date issue */
const dateTransformer: AxiosRequestTransformer = (data) => {
    if (data instanceof Date) {
        // do your specific formatting here
        var newDate = new Date(data);
        var os = newDate.getTimezoneOffset();
        newDate = new Date(newDate.getTime() - os * 60 * 1000);
        return newDate;
    }
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val));
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
    }
    return data;
};

axios.interceptors.request.use(
    (config) => {
        (config as RequestConfig).showLoading && store.dispatch(setLoading(true));

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        (response.config as RequestConfig).showLoading && store.dispatch(setLoading(false));
        store.dispatch(setIsActive(true));
        return response;
    },
    async (error: AxiosError) => {
        (error.config as RequestConfig).showLoading && store.dispatch(setLoading(false));

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        // const isActive = store.getState().Auth.isActive;
        const originalRequest = error.config;
        let asWarning = false;
        if (error && error.response) {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    window.location.href = '/account/logout';
                    break;
                case 403:
                    // window.location.href = '/access-denied';
                    message = 'Access Forbidden';
                    break;
                case 404:
                    asWarning = true;
                    message = 'Sorry! The data you are looking for could not be found';
                    break;
                default: {
                    let errData = error.response && error.response.data;

                    if (errData?.message) {
                        message = errData.message;
                    } else if (errData?.detail) {
                        message = errData.detail;
                    } else {
                        message = errData;
                    }
                }
            }

            console.error(error);
            if (error.config.url?.includes('/api/v1/addresses/') && error?.response?.status === 400) {
                const unconfirmedData = error?.response?.data?.unconfirmedComponents;
                const missingData = error?.response?.data?.missingComponents;
                if (missingData?.length > 0 && Array.isArray(missingData)) {
                    const missingDataString = missingData.join(',');
                    message = `The address you provided is missing ${missingDataString} . Please double-check the address and try again!`;
                } else if (unconfirmedData?.length > 0 && Array.isArray(unconfirmedData)) {
                    const unconfirmedDataString = unconfirmedData.join(', ');
                    message = `The ${unconfirmedDataString} you provided is unconfirmed. Please double-check the address and try again!`;
                } else {
                    message =
                        'The address you provided is not recognized. Please double-check the address and try again!';
                }
            }
            console.log({ persistError: !!(error.config as RequestConfig)?.persistError, c: error.config });
            const title = error.response.data?.title ? error.response.data.title : 'Error';
            const errors = error.response.data?.errors?.length > 0 ? error.response.data.errors : [message];

            if (!(error.config as RequestConfig)?.hideError) {
                if (!(error.config as RequestConfig)?.hide404) {
                    store.dispatch(
                        setAppError({
                            title,
                            errors,
                            warning: asWarning,
                            persistError: !!(error.config as RequestConfig)?.persistError,
                        })
                    );
                }
            }

            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

class APICoreClient {
    private eventSource: CustomEventSource | null = null;

    private getToken = () => {
        const state = store.getState();
        let token = state.Auth.authorization?.token;

        if (!token || token == null) {
            // Fallback: Retrieve from local storage (if applicable)
            const user = LocalStorage.getLoggedInUser();
            token = user?.token;
        }

        if (!token) {
            console.error('Token is missing from both Redux state and local storage.');
            return null; // or throw an error if the token is required
        }
        //check if token is expired
        return token;
    };

    private getConfig = (params?: RequestConfig): RequestConfig => {
        return {
            ...params,
            headers: {
                ...params?.headers,
                Authorization: params?.headers?.Authorization
                    ? params?.headers?.Authorization
                    : `Bearer ${this.getToken()}`,
            },
        };
    };

    createURL = (url: string, params: { [key: string]: any }) => {
        const request = Object.entries(params)
            .reduce(
                (acc, entry) => (!entry || !entry[0] || !entry[1] ? acc : [...acc, `${entry[0]}=${entry[1]}`]),
                [] as string[]
            )
            .join('&');
        return `${axios.defaults.baseURL}${url}?${request}`;
    };

    /**
     * Fetches data from given url
     */
    get = <ReturnType>(url: string, params?: RequestConfig) => {
        const axiosConfig = this.getConfig(params);
        return axios.get<ReturnType>(url, axiosConfig).then((response) => {
            return response.data;
        });
    };

    getEventSource = (
        url: any,
        onMessage: (event: any) => void,
        onError: (error: any) => void,
        params?: RequestConfig
    ) => {
        const axiosConfig = this.getConfig(params);
        const authorizationHeader = axiosConfig?.headers?.Authorization;
        let authHeaderString: string = '';

        if (authorizationHeader != null) {
            authHeaderString = authorizationHeader + '';
        }

        if (this.eventSource != null) {
            this.eventSource.close();
            this.eventSource = null;
        }

        this.eventSource = new CustomEventSource(url, {
            headers: {
                Authorization: authHeaderString,
            },
            retry: 3000,
        });

        this.eventSource.onopen = () => {
            console.log('Connection opened');
        };

        this.eventSource.onmessage = (event: MessageEvent) => {
            onMessage(event);
        };

        this.eventSource.onerror = (error) => {
            console.error('Error occurred:', error);
            onError(error);
        };

        return () => {
            if (this.eventSource !== null) this.eventSource.close();
        };
    };

    getParams = <ReturnType>(url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get<ReturnType>(`${url}?${queryString}`, params);
        } else {
            response = axios.get<ReturnType>(`${url}`, params);
        }
        return response.then((response) => {
            return response.data;
        });
    };

    getFile = <ReturnType>(url: string, params: any, config?: AxiosRequestConfig) => {
        let response;
        const axiosConfig = this.getConfig(config);
        axiosConfig.responseType = 'blob';
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get<ReturnType>(`${url}?${queryString}`, axiosConfig);
        } else {
            response = axios.get<ReturnType>(`${url}`, axiosConfig);
        }
        return response.then((response) => {
            return response;
        });
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    post = <ReturnType>(url: string, data: any, params?: RequestConfig) => {
        const axiosConfig = this.getConfig(params);
        return axios.post<ReturnType>(url, data, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url
     */
    create = <ReturnType>(url: string, data: any) => {
        return axios.post<ReturnType>(url, data).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url with Date transformer to addrss changing of Date when being converted to JSON
     */

    postWithDateTransformer = <ReturnType>(url: string, data: any, params?: RequestConfig) => {
        const axiosInstance = axios.create({
            transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
        });

        const axiosConfig = this.getConfig(params);

        return axiosInstance.post<ReturnType>(url, data, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * Updates patch data
     */
    updatePatch = <ReturnType>(url: string, data: any, params?: RequestConfig) => {
        const axiosConfig = this.getConfig(params);
        return axios.patch<ReturnType>(url, data, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * Updates data
     */
    update = <ReturnType>(url: string, data: any, params?: RequestConfig) => {
        const axiosConfig = this.getConfig(params);
        return axios.put<ReturnType>(url, data, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * Deletes data
     */
    delete = <ReturnType>(url: string, params?: RequestConfig) => {
        const axiosConfig = this.getConfig(params);
        return axios.delete<ReturnType>(url, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url with file
     */
    createWithFile = <ReturnType>(url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const axiosConfig: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post<ReturnType>(url, formData, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url with file
     */
    updateWithFile = <ReturnType>(url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const axiosConfig: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch<ReturnType>(url, formData, axiosConfig).then((response) => {
            return response.data;
        });
    };

    detachedSingleGet = <ReturnType>(url: string, id?: any, config?: RequestConfig) => {
        const axiosConfig = this.getConfig(config);

        return axios.get<ReturnType>(`${url}${id && id}`, axiosConfig).then((response) => {
            return response;
        });
    };
}

const APICore = new APICoreClient();

export { APICore };
